@import "~antd/dist/antd.css";

$fullWidth: 10rem;
$headerHeight: 3.148rem;

.header {
  background-image: url("assets/mobile/mHeader.png");
  background-repeat: no-repeat;
  background-size: $fullWidth $headerHeight;
  width: $fullWidth;
  height: $headerHeight;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .logo {
    text-align: center;
    font-size: 0.5rem;
    padding-bottom: 1rem;
    flex: 1;
    .span1 {
      color: aliceblue;
    }
    .span2 {
      color: aliceblue;
    }
  }
  .menuButtonDiv {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .menuButton {
      font-size: 0.5rem;
      color: aliceblue;
      text-decoration: none;
      margin-right: 30px;
      padding-bottom: 1rem;
    }
  }
}

.main {
}

//.footer {
//  background-image: url("assets/mobile/mHeader.png");
//  height: 340px;
//  width: 1080px;
//  display: flex;
//  justify-content: space-between;
//}
