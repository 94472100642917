@import "../../app_m.module.scss";

$footerHeight: 3.4537rem;

.container {
  font-size: 0.4rem;
  font-weight: bold;
  line-height: 0.8rem;
  min-height: 100vh;
  background-color: #f0f0f0;

  .videoContainer {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    .video {
      width: 100%;
      height: 5rem;
    }
  }
  .block {
    padding-left: 0.2rem;
    padding-right: 0.2rem;
    .textImg {
      padding-left: 0.3rem;
      padding-right: 0.1rem;
      width: 1.1rem;
      vertical-align: middle;
    }
  }
  .inputBlock {
    padding: 0.2rem 0.2rem 0.2rem 0.2rem;
    line-height: 1rem;
    input {
      border: none;
      background-color: #f0f0f0;
    }
    textarea {
      border: none;
      background-color: #f0f0f0;
    }
  }
  .spaceAroundBlock {
    padding: 0.2rem 0.2rem 0.2rem 0.2rem;
    line-height: 1rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .kuaidi {
      font-weight: normal;
      height: 0.8rem;
      width: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .cover {
      color: white;
      border: 1px solid #ef7b24;
      border-radius: 0.2rem;
      background-color: #ef7b24;
    }
    .nocover {
      color: black;
    }
  }
  .submitBlock {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .submitBtn {
      font-weight: bold;
      background-color: #ef7b24;
      border-color: #ef7b24;
      border-radius: 1.41rem;
      border-width: 1px;
      width: 4.5rem;
      height: 1.5rem;
    }
    .submitBtnDisabled {
      font-weight: bold;
      background-color: lightgrey;
      border-color: lightgrey;
      border-radius: 1.41rem;
      border-width: 1px;
      width: 4.5rem;
      height: 1.5rem;
    }
  }
}
