@import "../../app_m.module.scss";

$footerHeight: 3.148rem;;

.container {
  font-size: 0.4rem;
  font-weight: bold;
  line-height: 0.8rem;
  min-height: 100vh;
  background-color: #f0f0f0;

  .block0 {
    padding-bottom: 1rem;
    display: flex;
    .block01 {
      padding-left: 0.5rem;
      flex: 2;
      .mLabel {
        font-size: 0.5rem;
        font-weight: bold;
      }
      .mValue {
        font-size: 0.4rem;
      }
    }
  }
  .block1 {
    //每一项记录
    line-height: 0.8rem;
    .block11 {
      padding: 0.8rem 0.5rem 0.8rem 0.5rem;
      display: flex;
      //左侧内容
      .block111 {
        flex: 4;
        .block111_time {
        }
        .block111_no {
          display: flex;
          align-items: center;
          a {
            color: darkorange;
          }
          img {
            width: 0.3148rem;
            height: 0.361rem;
            margin-left: 0.1rem;
          }
        }
        .block111_status {
          span {
            font-weight: bold;
          }
        }
      }
      //右侧按钮
      .block112 {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          width: 0.75rem;
          height: 0.75rem;
        }
        span {
          margin-top: 0.05rem;
          color: red;
        }
      }
    }
    .block12 {
    }
  }
}

.footer {
  background-image: url("../../assets/mobile/mHistory_footer.png");
  background-repeat: no-repeat;
  background-size: $fullWidth $footerHeight;
  width: $fullWidth;
  height: $footerHeight;
  display: flex;
  justify-content: space-between;
}
